

import React from "react"
import Copyright from "./copyright"

const Footer = () => (
  <footer className="footer">
      <div className="columns">
          <div className="column is-narrow">
            Support
          </div>
          <div className="column  is-narrow">
            Legal
          </div>
          <div className="column  is-narrow">
            Careers
          </div>
      </div>
      <div className="columns">
          <div className="column is-full">
              <Copyright />
          </div>
      </div>
  </footer>
)

export default Footer
