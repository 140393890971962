import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Image from "../components/image"

import Header from "./header"
import Footer from "./footer"
import SEO from "../components/seo"
import Navbar from "../components/Navbar"
import "../pages/siteStyles.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <SEO title="Home" />
      <Navbar />
      <div className="max-height">
        <Image />      
      </div>
      <div className="section">
        <div className="container is-desktop">{children}</div>
      </div>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
